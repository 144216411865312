* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

::-webkit-scrollbar {
  width: 10px;
}

.horiz-scroll::-webkit-scrollbar {
  height: 8px;
}

.horiz-scroll::-webkit-scrollbar-thumb {
  background: #A90842;
  border-radius: 10px;
}

.horiz-scroll::-webkit-scrollbar-track {
  background: #686767;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #686767;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A90842;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #A90842;
  scale: 10.0;
}

/* no scrollbar**/
.no-scrollbar::-webkit-scrollbar {
  display: none;
}


.showcase-top{
	height: 100vh;
	width: 100%;
	position: relative;
	/*background: url('../images/9032d8130b91eeb57c44ee3dfcbde233.webp') no-repeat center center/cover;*/
}

.showcase-top::after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.15);
	box-shadow: inset 35px 60px 100px #0B0B0C;
}




.App {
  text-align: center;
  
}

.pic {
  background: url("../src/assets/images/hero_image.jpg") no-repeat center center/cover;
}

.montserrat {
  font-family: Montserrat Alternates, sans-serif;
}

.biryani-extralight {
  font-family: "Biryani", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.biryani-light {
  font-family: "Biryani", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.biryani-regular {
  font-family: "Biryani", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.biryani-semibold {
  font-family: "Biryani", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.biryani-bold {
  font-family: "Biryani", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.biryani-extrabold {
  font-family: "Biryani", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.biryani-black {
  font-family: "Biryani", sans-serif;
  font-weight: 900;
  font-style: normal;
}




.baloo-tamma-2-bal { 
  font-family: "Baloo Tamma 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.baloo-tamma-2-balight { 
  font-family: "Baloo Tamma 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


h1, h2, h3, h4, h5, h6 {
  /* font-family: Montserrat Alternates, sans-serif; */
  font-family: "Biryani", sans-serif;
  
  --tw-text-opacity: 1;
  color: rgb(14 37 87 / var(--tw-text-opacity));
}

.blue {
  color: rgb(14 37 87 / 1);
}

.blue_overlay {
  background: rgba(7, 25, 65, 0.9);
  
}

.bg_image {
  background: url("../src/assets/images/hero_image.jpg") no-repeat center center/cover;
}

.bshadow {
  box-shadow: inset 0 -0.5em 0 #0e2557;

}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.651);
  border-radius: 0.1rem;
  color: whitesmoke;
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid rgb(255, 255, 255);
  border-top: none;
  /* transition: top 0.3s ease-in-out; */
 
}

.sticky-at-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgb(14 37 87 / 0.9);
  border-radius: 0.1rem;
  color: whitesmoke;
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom: 3px solid rgb(33 , 150 , 83, 1);
  border-top: none;
  /* transition: top 0.3s ease-in-out; */
 
}

*{
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

canvas {
  height: 100vh !important;
}